<template>
  <div id="mobile-landing" class="page">
    <div id="mobile-landing-more" :class="{'more-depth': $route.meta.headerType === 'more-depth'}">
      <router-view />
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import Footer from "@/components/outline/v2/Footer.vue";

export default {
  name: "MobileLandingMore",
  data() {
    return {
      innerWidth: window.innerWidth,
      depth: 0,
      menuItems: {},
      selectedMenu: "",
      selectedLang: "",
    };
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.addEventListener("resize", this.onResize);
  },

  computed: {
    isLogin() {
      return !!this.$store.state?.auth?.gid;
    },

    userName() {
      let shortName = this.$store.state.auth?.user_name;
      let fullName =
        this.$store.state.auth?.user_last_name && this.$store.state.auth?.user_last_name
          ? this.$store.state.auth?.user_last_name + " " + this.$store.state.auth?.user_last_name
          : "";

      return shortName || fullName;
    },

    authPrimaryText() {
      return this.userName || this.$__t("로그인");
    },

    authSecondaryText() {
      return (
        this.$store.state.auth?.user_email ||
        this.$store.state.auth?.account ||
        this.$__t("럭스테이로 편하게 보관하세요.")
      );
    },

    isSidebar() {
      return (
        (this.$store.state.config.isTouchDevice && ["Stores", "StoreSingle"].includes(this.$route.name)) ||
        !this.$store.state.config.isTouchDevice
      );
    },

    toUsage() {
      return { name: "Tickets" };
    },

    footerVisible() {
      return !["MoreTickets", "MoreBillings", "MoreBillingAddPayMethod"].includes(this.$route.name);
    },
  },

  methods: {
    openMenuDepth(menu) {
      this.depth += 1;
      this.selectedMenu = this.menuItems[menu];
    },

    openChannelTalk() {
      this.$channelTalk.openChat();
    },

    onClickMenuItem(eventName, options) {
      this.$analytics.logEvent(eventName, options);
    },

    onResize(e) {
      this.innerWidth = window.innerWidth;
    },

    setLanguage(locale) {
      if (!this.$store.state.auth.gid) {
        localStorage.setItem("lugstay_language", locale);
        window.location = utils.removeURLParameter(window.location.href, "language");
        this.$analytics.setLanguage(locale);
        this.$router.go("/");
        return;
      }

      this.$store
        .dispatch("auth/edit", {
          user_language: locale,
        })
        .finally(() => {
          localStorage.setItem("lugstay_language", locale);
          window.location = utils.removeURLParameter(window.location.href, "language");
          this.$analytics.setLanguage(locale);
          this.$router.go("/");
        });
    },

    onClickBack() {
      this.depth -= 1;
      this.$router.back();
    },
  },

  components: {
    Footer,
  },
};
</script>
